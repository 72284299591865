import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {BioItemConatiner, BioItemConatinerRight, HalfPhoto, HalfText} from "./StyledBioItem";

const DoubleItem = (props) => {
  const item = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }

    gsap.from(item.current, {
      autoAlpha: 0,
      scale: 0.7,
      scrollTrigger: {
        trigger: item.current,
        start: "top bottom",
        end: "top 150",
        markers: false,
        scrub: true,
        anticipatePin: 1,
      },
    });
  });

  return  (

    <BioItemConatinerRight ref={item}>
      <HalfPhoto src={props.img}  alt="" />
      <HalfText>{props.text}</HalfText>
      <HalfPhoto src={props.img2}  alt="" />
    </BioItemConatinerRight>
  );
};

export default DoubleItem;
