import React from 'react';
import Layout from "./layout"

import messages from '../../intl/en.json';


export default (props) => (
  <Layout
    {...props}
    messages={messages}
  />
);
