import styled from "styled-components"
import Image from "./image"

export const WorkContainer = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
  }
`

export const TopPhoto = styled(Image)`
  position: relative;
  flex-grow: 1;
  height: 38%;
  width: 100%;
  object-fit: contain;
  @media (max-width: 600px) {
    height: 18%;
    object-fit: cover;    
    flex-grow: 1;
  }
`

export const BottomText = styled.div`
  height: 62%;
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 2rem;
  margin-top: 7%;
  margin-left: 5%;
  margin-right: 5%;
  @media (max-width: 600px) {
    font-size: 1.2rem;
    font-weight: 400;
    flex-grow: 1;
    padding-top: 7%;
    padding-bottom: 7%;
    height: auto;
    width: 90%;
  }
`


export const LogoGrid = styled.div`
margin: 2rem;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    width: 100%;
  }

`

export const Cont = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
margin: 1rem;

`


export const LogoPhoto = styled(Image)`
  flex: 1;
  max-width: 100px;
  @media (max-width: 600px) {
    min-width: 40px;
    
  }

`
