import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  TopPhoto,
  WorkContainer,
  BottomText,
  LogoGrid,
  LogoPhoto,
  Ul,
  Cont,
} from "./StyledWorkItem"
import { Button } from "./StyledHome"

const WorkItem = props => {
  const item = useRef(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.from(item.current, {
      autoAlpha: 0,
      scale: 0.7,
      scrollTrigger: {
        trigger: item.current,
        start: "top bottom",
        end: "top 150",
        markers: false,
        scrub: true,
        anticipatePin: 1,
      },
    })
  })

  return (
    <WorkContainer ref={item}>
      <TopPhoto src={props.img} alt="" />
      <BottomText> {props.text} </BottomText>
      <LogoGrid>
        <Cont>
          <LogoPhoto src={"js.png"} />
        </Cont>

        <Cont>
          <LogoPhoto src={"ts.png"} />
        </Cont>
        <Cont>
          <LogoPhoto src={"node.png"} />
        </Cont>
        <Cont>
          <LogoPhoto src={"react.png"} />
        </Cont>
        <Cont>
          <LogoPhoto src={"py.png"} />
        </Cont>
        <Cont>
          <LogoPhoto src={"java.png"} />
        </Cont>
        <Cont>
          <LogoPhoto src={"mongo.png"} />
        </Cont>
      </LogoGrid>
    </WorkContainer>
  )
}

export default WorkItem
